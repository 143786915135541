<script>
  import { call } from 'vuex-pathify'
  import { ApiError } from '@/api'
  import AddressForm from '@/components/account/AddressForm'

  export default {
    components: {
      AddressForm,
    },
    props: {
      storeType: {
        type: String,
        required: true,
        validator: (value) => ['marketplace', 'essentials'].includes(value),
      },
    },
    data() {
      return {
        formRef: null,
      }
    },
    computed: {
      cart() {
        return `${this.storeType}Cart` // marketplaceCart, essentialsCart
      },
    },
    methods: {
      ...call('account', ['addAddress']),
      async submit(address) {
        try {
          const newAddressId = await this.formRef.process(this.addAddress(address))
          this.$store.set(`${this.cart}/selectedShippingAddressId`, newAddressId)
          this.$navigate({ name: 'CheckoutShipping' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <AddressForm v-ref="formRef" @submit="submit($event)">
      Add a new address
      <template v-slot:buttonLabel>Use this address</template>
    </AddressForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'CheckoutShipping' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
